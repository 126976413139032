.banner-about{
    background-image: url('../../images/about.jpg');
    padding: 3rem;
    /* border-radius: 2rem; */
    margin: 2rem 0rem 2rem;
    color: #000;
}

.aboutStack{
    color:#000;
    /* border: 1px solid #000; */
    /* margin-bottom: 2rem; */
}

.aboutStack > div {
    
    /* border: 1px solid #000;    */
}
.content-about{
    /* text-indent: 4em; */

}
.subhead{
    /* margin-bottom: 2rem; */
}
.subhead::first-letter {
    /* initial-letter: 2; */
    -webkit-initial-letter: 2 1;
    initial-letter: 2 1;
    color: #052B65;
    font-weight: bold;
/* margin-right: .75em; */
  }

.about-header{
    position: relative;
}  
.about-header:after{
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 25px;
    left: 50%;
    margin-left: -50px;
    background-color: #90CAF9;
}

.about-header:before{
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: -1px;
    left: 50%;
    margin-left: -14px;
    background-color: #90CAF9;
}
.tagline{
    text-align: justify;
}

.cardbackground{
    background-color: #f7f7f7;
    height: 18rem;
}

.radiologist-cards{
    
}

.tagline::before {
    /* position: absolute; */
    content: "\275D";
    /* font-family: 'Noto Sans JP', sans-serif; */
    font-size: 2rem;
    color: gray;
    top: 0;
    left: 16px;
  }
  
  .tagline::after {
    content: "\275E";
    /* position: absolute; */
    /* font-family: 'Noto Sans JP', sans-serif; */
    font-size: 2rem;
    color: gray;
    bottom: -10px;
    right: 16px;
  }

  .tagline::after,
.tagline::before {
  /* color: #000;
  font-size: 28px; */
  margin: 0 4px;
}
.why-vital{

    background-image: url(../../images/why-vital.jpg);
    padding: 48% 15% 32%;
    background-position: cover;
    background-repeat: space;
    background-size: contain;
    margin-bottom: 2rem;
    margin-left: 11%;
}

.top-fit{
    padding: 2rem;
    text-align: center;
}

.top-fit > h3{
    font-weight: 600;
}

.redefine-cyc{
    background-image: url(../../images/web-med.jpg);
    /* background-position: cover; */
    background-repeat: no-repeat;
    background-size: contain;
    height: 29rem;
}

@media (max-width:500px) {

    .redefine-cyc{
        background-image: url(../../images/mob-med.jpg);
        /* background-position: cover; */
        background-repeat: no-repeat;
        background-size: contain;
        height: 50rem;
    }

    .banner-about {
        padding: 1rem;
    }

    .tagline{
        text-align: center;
    }
    
}
@media (max-width:410px) {
    .redefine-cyc{
    height: 46rem;
    }
}
@media (max-width:380px) {

    .redefine-cyc{
        height: 43rem;
    }
    
}

@media (max-width:768px){

    .card-profile{
        
        margin-bottom: 2rem;
    }
    .web-radialogy{
        display: none;
    }
    
}

@media (min-width:768px){

    .mob-radialogy{
        display: none;
    }

    .doctor-wrapper{
        padding-top: 0 !important;
        position: relative;
        bottom: 5rem;
    }

    .cardbackground{
        /* display: flex;
        justify-content: center; */
        position: relative;
        top: 5rem ;
        /* width: 90%; */
        margin: 0rem 1rem 0rem;
    }
   
}


 