.multi-background{
    background-image: url("../../../images/pencilArt.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 165% 84%;
  }

  .ball-background{

    background: url('../../../images/blueball.png') left -10px bottom -117px / 110% 1065px no-repeat, linear-gradient(-45deg, rgb(169, 241, 223) 16%, rgb(255, 187, 187)) rgb(108, 14, 224);;
    background-repeat: no-repeat;
  }

  @media (max-width: 992px) {
  .ball-background{
    background-size: cover;
  }
}