

    

    /* my card design  */



    .branch-subtitle{

        line-height: 0;
        font-size: 0.8em;
        font-weight: 500;
        color: #dc3545!important;

    }

    .branch_card:hover {
        transform: translateY(-10px);
    }

    .branch_card{

        border: none;
        box-shadow: 0 6px 26px rgb(0 0 0 / 6%);
        transition: .5s;    
        /* width:297px; */
        /* padding: 0 35px; */
    }

    .map-view{
        
    position: absolute; 
    top: 0;    
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../images/map.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.2;
    }

    .map-text{

        textDecoration: none;
        color: inherit;
        backgroundColor: lightblue;
        padding: 10px 20px;
        borderRadius: 5px;
        opacity: 1;
    }

    .city-btn {
        position:relative;  
        margin: 0 10px 10px 0;
        max-width: fit-content;
        width: fit-content;
        border-radius: 10px !important;
        /* margin: 2px !important; */
        border: none;
        background-color: #ffffff ;
        border: 1px solid black;
        color: #000 ;
        font-size: 0.7rem;
      }


      .cities-btns{
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      .cities-btns > .active {
        background-color: #2d5e43 !important;
        border: 1px solid #2d5e43;
        color:#fff !important;
      }

      .city-btn > input {
        opacity: 0;
        position: absolute;
      }

      .city-btn:hover{
        background-color: #ffffff !important;
        border: 1px solid black;
        color: #000 !important;

      }

      .body-address{
            min-height: 98px;

      }

    