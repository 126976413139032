
.questions-row{
    margin: 2rem;
    border: 1px solid black;
    height: auto;
}

.question-col{

    padding: 2rem;

}

.animate-card {
  animation: wiggle 2s linear infinite;
}

.answers-btns{

    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

.question-btn{
    width: 9rem;
    margin-right: 2rem; 
    margin-bottom: 1rem;   

}
.riskAssesment > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.answers-btns > .btn:focus, .answers-btns > .btn-outline-info:focus, .focus:focus{
    background-color: none !important;
    background: none !important;
    /* --bs-btn-active-bg:none !important */
}

.email-modal-label{
  margin-left: 2rem;
  margin-right: 2rem;
}


.butn-cont{
  text-align: center;
  margin-top: 3rem;

}

.answer-btn > input {
    opacity: 0;
    position: absolute;
    width:0rem;
  }

  .answer-btn{
    /* width: 100%; */
    height: 4rem;
    padding: 1.2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    flex: none !important;
  }

  .select-col{

    margin-left: 6rem;
  }

  div:where(.swal2-container) input:where(.swal2-input){
    margin: 0 !important;
  }

  @keyframes wiggle {
    0%, 7% {
      transform: rotateZ(0);
    }
    15% {
      transform: rotateZ(-1deg);
    }
    20% {
      transform: rotateZ(1deg);
    }
    25% {
      transform: rotateZ(-1deg);
    }
    30% {
      transform: rotateZ(1deg);
    }
    35% {
      transform: rotateZ(-1deg);
    }
    40%, 100% {
      transform: rotateZ(0);
    }
  }

  @media (max-width: 769px) {

    .answers-btns{
        flex-flow: column;
    }

    .select-col{

        margin-left: 0rem;
      }

  }

  @media (max-width: 500px){

    .question-col{
      padding: 0rem;
    }

    .questions-row{
      border:0;
    }

    .answer-btn{
      min-width: 100%;
      /* width: max-content; */
      height: max-content;
    }

    .question-btn{
      margin-right: 0rem ;
      margin-bottom: 0rem ;
      font-size: 15px;
      width: 7rem;

    }

    .question-btn:first-child {
      margin-right: 1rem ;
    }
    

  }

  