.single-hero-slide {
    background-color: #F3DBDC;
    margin: 0% 2% 2% 2%;
    font-family: 'Poppins', sans-serif;
    border-radius: 78px;
    /* padding: 3.1rem 2rem 2.4rem; */
    /* background: linear-gradient(-45deg, rgb(169, 241, 223) 16%, rgb(255, 187, 187)) rgb(108, 14, 224); */
}

.top-content{
    letter-spacing: -.025em;
    color: #000;
    font-size: 3.75rem;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 1rem;
}

.hero-slide-left {
    /* padding-right: 60px; */
    /* margin-left: 35px; */
    margin-left: 10px;
    padding: 3.1rem 0rem 2.4rem 2rem;
}



.hero-slide-left h2 {
    font-size: 36px;
}

.hero-slide-left h1 {
    font-size: 62px;
    line-height: 70px;
    margin-bottom: 20px;
    font-weight: 800;
}

.hero-slide-left p {
    color: #000000!important;
    /* font-family: 'Roboto Slab', serif;; */
    font-family: 'Poppins', sans-serif;
    line-height: 140%;
    font-size: 1.1rem;
}


.heroTeeth {
    max-width: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

.sub-header{

    background-color: white;
    border-radius: 2rem;
    color: black;
    padding :1.5rem;
}

.subHeader-list > li{

    display: flex;
    align-items: center;
    /* padding: 1.9rem 1.3rem 1.8rem; */
    padding: 0.4rem;
    border-radius: 1.4rem;
    border: 0.01rem solid #efefef;
    background-color: transparent;
    margin-bottom: 1rem;

}
.subHeader-list{
    padding-left: 0;
}



.theme-btn {
    display: inline-block;
    border: 1px solid #fc5185;
    height: 61px;
    text-align: center;
    line-height: 55px!important;
    padding: 0 55px;
    background: #fc5185;
    text-transform: capitalize;
    position: relative;
    border-radius: 3px;
    color: #fff;
    transition: .4s;
    font-weight: 600;
}

.btn-fill {
    /* margin-right: 15px; */
    color: #000 !important;
    background-color: #46e0d3;
    border-color: none;
    border: 1px solid #46e0d3;
}

.btn-fill:hover {
    background: #fff;
    color: #1a59c0!important;
    border: 1px solid #1a59c0;
}

.bth-blank {
    border-width: 1px;
    border-color: rgb(209 213 219/var(--tw-border-opacity));
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(55 65 81/var(--tw-text-opacity));

}

.banner-btn{
    margin: 2rem 0rem 3rem 0rem !important;
  
}

.bth-blank:hover {
    border-color: #fc5185;
    color: #fff;
    background: #fc5185;
}

.animate-img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 22;
}

.animate-img .aimg1 {
    left: 210px;
    position: relative;
    animation: movebounce 2s ease-in-out infinite;
    animation-delay: 100ms;
    animation-direction: alternate;
    bottom: 15px;
}

.animate-img .aimg2 {
    position: relative;
    left: 265px;
    top: 30px;
    animation-delay: 200ms;
    animation: movebounce 2.5s ease-in-out infinite;
    animation-direction: alternate;
}

.subHeader-list li:nth-child(1)::before{
    content: url('../../images/allInclusive.png'); 
    transform: scale(0.6); 
    /* margin-right: 5px;  */
}

.subHeader-list li:nth-child(2)::before{
    content: url('../../images/detect.png'); 
    transform: scale(0.6); 
    /* margin-right: 5px;  */
}

.subHeader-list li:nth-child(3)::before{
    content: url('../../images/protection.png'); 
    transform: scale(0.6); 
    /* margin-right: 5px;  */
}
.sub-header > h5{

    margin-bottom: 1rem;

}

@keyframes movebounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}


/* for mobile responsive */
@media (max-width: 1024px) {
    /* banner */
    /* .animate-img .aimg1 {
        left: 209px;
        top: 133px;
    }

    .animate-img .aimg2 {
        left: 265px;
        top: 127px;
    } */
}

@media (max-width: 992px) {
    /* banner */
    /* .animate-img .aimg1 {
        left: -25px;
        top: 381px;
    }

    .animate-img .aimg2 {
        left: 27px;
        top: 417px;
    } */

    .hero-slide-left {
        padding: 0;
        margin-top: 2rem;
    }
}

@media (max-width: 769px) {
    /* banner */
    /* .animate-img .aimg1 {
        left: -25px;
        top: 381px;;
    }

    .animate-img .aimg2 {
        left: 27px;
        top: 429px;
    } */
}

@media (max-width: 576px) {
    .hero-slide-right {
        margin-top: 70px;
    }
    .single-fun span {
        font-size: 2rem !important;
    }
    .single-fun p {
        font-size: 0.7rem !important;
    }

}

@media (max-width: 512px) {
    /* banner */
    .animate-img .aimg1 {
        left: -92px;
        top: 605px;
    }

    .animate-img .aimg2 {
        left: 102px;
        top: 463px;
    }

    .hero-slide-left h1{
        font-size: 40px;
        line-height: 3rem;
    }
}

@media (max-width: 461px) {
    /* banner */
    /* .animate-img .aimg1 {
        left: -91px;
        top: 682px;
    }

    .animate-img .aimg2 {
        left: 94px;
        top: 555px;
    } */
}

@media (max-width: 445px) {
    /* banner */
    /* .animate-img .aimg1 {
        left: -88px;
        top: 799px;
    }

    .animate-img .aimg2 {
        left: 94px;
        top: 671px;
    } */

    .banner-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 426px) {
    .single-hero-slide {
        /* padding: 3rem 0rem 2.4rem; */
        border-radius: 59px;
    }
    .hero-slide-left{
        /* padding: 3rem 2rem 2.4rem 2rem; */
        /* padding: 1.5rem 1.2rem 0rem 1.2rem; */
    }


    /* .animate-img .aimg1 {
        top: 605px;
        left: -85px;
    }

    .animate-img .aimg2 {
        left: 94px;
        top: 467px;
    } */
}

@media (max-width: 376px) {
    .single-hero-slide {
        /* padding-bottom: 85px; */
    }

    .animate-img .aimg1 {
        top: 597px;
        left: -72px;
    }

    .animate-img .aimg2 {
        left: 94px;
        top: 462px;
    }
    
}

@media (max-width: 321px) {
    .container-head {
        max-width: 90%;
    }


    .animate-img .aimg1 {
        max-width: 65%;
        top: 575px;
        left: -61px;
    }

    .animate-img .aimg2 {
        max-width: 65%;
        left: 80px;
        top: 469px;
    }

}