.whyMri-wrapper {
  padding: 4% 0;
  background-color: transparent;
}

.image-box {
  border-radius: 10px;
  background-image: url('../../images/lab1.svg');
  padding: 48% 15% 32%;
  background-position: cover;
  background-repeat: space;
  background-size: contain;
  margin-bottom: 2rem;
  margin-left: 11%;
}

.why-button{
  margin-top: 17px;
  color: #46e0d3;
  font-size: 16px;
  text-decoration: none;
  line-height: 24px;
  text-transform: capitalize;
  font-weight: 700;
  padding-bottom: 4px;
  display: inline-block;
  border-bottom: 2px solid #46e0d3;
  transition: all 0.4s ease 0s;
}

