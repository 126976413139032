/* ============ MIN WIDTH 1400 ========== */
@media (min-width: 1400px) {
  .loginBottomImg {
    right: 15%;
  }
}

/* ============= 992px to 1200px ============= */
@media (min-width: 992px) and (max-width: 1200px) {
}
/* =============  992 to 1240 ============= */
@media (min-width: 992px) and (max-width: 1240px) {
}
/* =============  0 to 1240 ============= */
@media (min-width: 0px) and (max-width: 1240px) {
  /* ========== SETTINGS > REPORT =============== */
  .reportContainer {
    flex-direction: column;
  }

  .reportSidebar {
    height: auto;
    display: flex;
    align-items: center;
  }

  .reportTypes {
    display: flex;
    gap: 15px;
    margin-left: 15px;
  }

  .reportSidebar h2.titleMd {
    margin-bottom: 0;
  }

  .reportTypes p {
    margin-bottom: 0 !important;
  }
  /* =========== HOME SCREEN ========= */
  .APSAndBookingWrapper {
    flex-direction: column;
  }

  .appointmentCardWrapper {
    flex-direction: column;
  }

  .weekAppointmentRowWrapper {
    flex-direction: column;
  }

  .weekAppointmentWrapper {
    overflow: auto;
  }

  .weekAppointmentHeader {
    flex-direction: column;
    gap: 5px;
  }
}

/* ============= 0 to 992 ============= */
@media (min-width: 0px) and (max-width: 992px) {
  .column {
    padding: 0 !important;
  }
  /* ========== SETTING > REPORTS ======== */
  .optionWrapper {
    margin-top: 25px;
  }
  /* ========= SETTING > CREW ========== */
  .crewListGridWrapper {
    padding: 0px;
  }
  /* ========= SETTING > SERVICES > SUB SERVICE =========== */
  .allQuestionTypes {
    flex-direction: column;
  }
  /* ========== SHARE PROFILE ======== */
  .shareProfile {
    flex-direction: column;
    padding: 25px;
  }
  /* ========== LOGIN SCREEN ============ */
  .loginRightColumn {
    display: none;
  }
}
/* ============= 0 to 768 ============= */
@media (min-width: 0) and (max-width: 768px) {
  /* ============ MENU BAR ========== */
  .topbar-wrapper {
    display: none;
  }
  .leftside-menu {
    display: none;
  }
  .mobileMenu {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background: #1f262e;
    color: #fff;
    padding-right: 10px;
    position: fixed;
    top: 0;
    display: flex;
  }
  .mobileMenu div:nth-child(1) img {
    width: 115px;
  }
  .mobileMenu div:nth-child(1) {
    background: #f17222;
    padding: 10px;
  }
  .menu-items-wrapper {
    background: #1f262e;
    position: fixed;
    width: 100%;
    top: 90px;
    max-height: 300px;
    overflow: auto;
  }
  .mobileMenu svg {
    font-size: 30px;
  }
  .content-page {
    margin: 0;
    padding: 90px 0 0;
  }
  /* ============ SETTING > MANAGE TEAM =========== */
  .column {
    padding: 0 !important;
  }
  .manageTeamContainer .MTTitleWrapper {
    padding: 15px 15px 0;
  }
  /* ============== SETTINGS > CREW ============== */
  .grid.table td {
    padding: 10px;
  }

  .grid.table .labelLg {
    font-size: 14px;
  }

  .crewListGridWrapper {
    padding: 0;
  }

  .titleWrapper {
    padding: 0;
  }
  .crewDefaultSelectMainWrapper {
    right: 0;
    top: 40px;
  }
  .crewDetailsSelectWrapper:before {
    border-right-color: transparent;
    border-bottom-color: #707070;
    top: -31px;
    right: 0px;
    border-width: 15px;
  }
  .crewDetailsSelectWrapper:after {
    border-right-color: transparent;
    border-bottom-color: #f4f4f4;
    top: -30px;
    right: 0px;
  }
  /* ========= SETTINGS > SERIVE AREA ========= */
  .yourRegionWrapper > .labelMd:nth-child(1) {
    padding-top: 30px;
  }
  /* ========== SETTINGS > SUB SERVICE =========== */
  .serviceTimeMainWrapper {
    flex-direction: column;
  }

  .subServiceGridWrapper thead tr > td:nth-child(1) {
    min-width: 200px !important;
  }

  .subServiceGridWrapper thead tr > td:nth-child(2),
  .subServiceGridWrapper thead tr > td:nth-child(3) {
    min-width: 150px !important;
  }
}

/* ============ 0 to 595 ============ */
@media (min-width: 0) and (max-width: 595px) {
  /* ============== SETTINGS > REPORTS ============ */
  .reportsGridWrapper .titleMdWrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  /* ========== HOME SCREEN ============ */
  .HTBadgeWrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .appoinmentPerZoneWrapper {
    margin-top: 20px;
    padding: 20px;
  }

  /* ======= SETTING > SERVICE > SUB SERVICE =========== */
  .SSOptionsGroup {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  .SSOptionsGroup .form-group {
    margin-bottom: 0 !important;
  }

  /* ======== SETTING > COMPANY PROFILE ========== */
  .companyProfileFormWrapper .cradentialsWrapper .flexDefault {
    flex-direction: column;
    gap: 0;
  }

  /* =========== LAYOUT =========== */
  .colorPickerWrapper {
    flex-direction: column;
  }

  .colorPickerWrapper input {
    width: 100%;
    max-width: 100%;
  }

  .colorPickerWrapper {
    gap: 10px;
  }

  .CIFormWrapper {
    flex-direction: column;
    gap: 10px;
  }

  .BHTitleWrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .BHTitleWrapper h3 {
    margin-bottom: 10px;
  }

  /* =========== AVAILABILITY > HOURS OF OPERATIONS > EDIT BUSINESS HOURS ========== */
  .editBusinessHoursWrapper {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  /* =========== AVAILABILITY > HOURS OF OPERATIONS > EDIT MODIFIED TIME ========== */
  .businessHourListWrapper {
    flex-direction: column;
  }

  .editBusinessHoursWrapper > div:nth-child(3) {
    margin-left: 0;
  }

  .dateRangePickerWrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .businessHourListWrapper {
    gap: 0;
  }

  .editBusinessHoursWrapper {
    margin-bottom: 10px;
  }

  .businessHoursReasonGroup {
    margin-bottom: 15px;
  }

  /* =========== AVALIBILITY > NEW BLACKOUTS ============*/
  .blackoutDateTimeRange div:nth-child(1) {
    flex-direction: column;
    gap: 5px;
  }

  .recurrFields {
    flex-direction: column;
    align-items: flex-start;
  }

  /* ========= REFER A FRIENDS =========== */
  .communityWrapper {
    flex-direction: column;
    gap: 10px;
  }

  .yourReferalWrapper {
    margin-top: 30px;
  }

  .giftCardInfoWrapper {
    flex-direction: column;
  }

  /* ========= BOOKING > LIST ======== */
  .listItemWrapper {
    flex-direction: column;
  }

  .listToolbar {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .listToolbar .form-group input {
    width: 100%;
  }

  .listToolbar .form-group {
    width: 100%;
  }

  .listToolbar button {
    width: 100%;
  }
  /* ========== SHARE PROFILE ========== */
  .referFriendSocialLinks {
    flex-direction: column;
    gap: 20px;
  }

  /* ======== LOGIN SCREEN ========== */
  .loginFormWrapper {
    max-width: 100%;
  }

  .loginLeftColumn {
    flex-basis: 100%;
  }

  .loginInput {
    min-width: 100%;
  }

  .signUpFormWrapper {
    max-height: 100%;
  }

  .loginLogo {
    position: fixed;
  }

  .signUpMainWrapper {
    margin-top: 120px;
    overflow: auto;
    margin-bottom: 30px;
  }
}
/* =========== 0 to 399 ============ */
@media (min-width: 0) and (max-width: 399px) {
  /* ============ HOME SCREEN =========== */
  .alertMessage {
    flex-direction: column;
    padding: 15px;
    gap: 10px;
  }
  .chartLegendWrapper {
    display: none;
  }

  .appointmentCard {
    min-width: auto;
  }
  /* =========== TITLE WRAPPER ========= */
  .titleWrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
