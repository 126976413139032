/* @import url('https://fonts.cdnfonts.com/css/sofia-pro'); */



/* for mobile responsive */
@media only screen and (max-width: 426px){
    .healing {
        padding: 0 !important;
    }

    .p-5 {
        padding: 6px !important;
    }

    .pt-xs-5 {
        padding-top: 40px;
    }
    .table-container{

        margin-top: 10%;
    }
}

@media (max-width: 996px) {
  .below-legend {
    display: contents;
  }
  .above-legend {
    display: none;
  }
}

@media (min-width: 997px) {
  .below-legend {
    display: none;
  }
  .above-legend {
    display: contents; 
  }
}







.expert-list{
        font-size: 13px;

}


.scan-table > tbody > tr td:first-child {

    font-weight: 450;

}



      /** multi select bootstrap button **/
      /* box-shadow: 3px 3px 2px 0px rgba(128, 0, 0, 1); */
      .orgn-btn {
        position:relative !important;  
        margin: 0 10px 10px 0 !important;
        max-width: fit-content !important;
        width: fit-content !important;
        border-radius: 10px !important;
        border: none !important;
        background-color: #E5ECEE !important;
        color: #056269 !important;
        font-size: 0.7rem !important;
      }


      .organs-btns{
        display:flex;
        flex-wrap: wrap;
      }


      .organs-btns > .active {
        /* background-color: #A9F1DF !important; */

        color: #000 !important;
        background-color: #2d5e43 !important;
        color: #fff !important;
      }

      .orgn-btn > input {
        opacity: 0;
        position: absolute;
      }

      .orgn-view{
        /* font-family: sofia-pro, serif; */
        background-color: rgb(229, 236, 238);
        /* color: #72b3a3 !important; */
      }

      .organDescri-header{

        background-color:#fff !important;
        /* border-bottom: 0.25rem dotted #7ed2bd; */
        border: none;
        color: #013539 !important;
        font-size: 1.5rem !important;
      }
      .organDescri{
        background-color:#fff !important;
        border-radius: 12px;
        /* height: 18rem; */
        border: 1px solid rgb(228, 228, 228);
        border-radius: 1.25rem;
        font-size: 0.875rem;
        width: 23rem;
        margin-bottom: 1.5rem;
        padding: 0px !important;
        
      }

      .key-count{
        color: #013539;
        font-size: 0.975rem;
        margin-left: 3%;
        padding-top: 2%;
      }

      .pagination > .page-item > .page-link  {
        border: none !important;
        background-color: #E5ECEE !important;
        color: #013539;
       
      }
      .page-item.active .page-link {
        background-color: #b8eee7 !important;
        border-color: #b8eee7 !important;
        color: white !important;
        /* margin-right: 10px !important; */
      }

      .our-tech-container{
        background-color: #c7dffa;
        border-radius: 3.8rem;
        padding: 2.5rem 2.2rem 2.4rem;
        /* background: rgba(255, 255, 255, 0.4); */
      }
      .our-tech > h5{

        color:#55abc7;
        margin-bottom: 2%;
      
      }

      .our-tech > h2{
        /* margin-bottom: 1%; */
        color:dimgrey
      }

      .our-tech > p{

        /* color:dimgrey */

        font-size: small;
      }
   
    
