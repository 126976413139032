/* .sticky-container {
    padding: 1rem 2rem 1rem;
    background-image: linear-gradient( 95.2deg, rgba(173,252,234,1) 26.8%, rgba(192,229,246,1) 64% );
    z-index: 200;
  }

  .coupon .kanan {
    border-left: 1px dashed #ddd;
    width: 40% !important;
    position:relative;
}

.coupon .kanan .info::after, .coupon .kanan .info::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #dedede;
    border-radius: 100%;
}
.coupon .kanan .info::before {
    top: -10px;
    left: -10px;
}

.coupon .kanan .info::after {
    bottom: -10px;
    left: -10px;
}*/
.coupon  {
    font-size: 1.6rem;
    box-shadow: 1px 1px 4px rgba(0,0,0,.25);
}

.lead{
font-weight: 500;

}
.sticky-input{
    border: 2px solid gray !important;
    border-right: none !important;

}

.tengah{
    padding: 1rem 1.5rem 1rem 1.5rem !important; 
}

@media (max-width: 426px) {

    .kiri{
        display: none;
    }

    .tengah{
        /* padding: 0.5rem 1.5rem 1rem 1.5rem !important;  */
        padding: 0.5rem !important;
    }


}

   