@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&family=Lora:ital,wght@0,400;0,500;0,600;1,400&family=Roboto+Slab:wght@300;400;500;600;700&display=swap');


.container-head {
    background: #ffffff;
    border-radius: 5px;
    transition: all .5s ease-in-out;
    padding: 0 30px;
    /* margin-top: 5px; */
}
.image-size{
    /* width:79px;
    height:58px; */
    width: 12rem;
    height: 4rem;
}
.button-margin{

    margin-left: 109px;
}


.button-yellow:hover {
    color: #002265;
    background-color: #fff;
    
  
}
.button-yellow {
    background-color: #002265;
    color: #fff;
    border: 1px solid #002265;
}

.head-bg {
    background-color: #ffffff;
}


.list-item {
    padding: 20px 0;
    display: inline-block;
    color: #000;

    
    text-transform: capitalize;
    padding-left: 63px;
    font-size: 1rem;
    font-weight: 500;
}

.list-item:hover {
    --tw-text-opacity: 1;
    color: rgb(217 119 6/var(--tw-text-opacity))
}

.ml-5 {
    margin-left: 20px !important;
}

.ml-5:hover {
    border-color: #fc5185 !important;
}

.userName {
    color: #004caa;
    margin-left: 5px;
}

.nav-support{
    background-color: #F7F7F7;
}

.nav-support > a{
    color: #000000;
    text-decoration: none;
    font-size:0.7rem;

}


  /* for mobile responsive */

@media (max-width: 992px) {
    /* 2: Navbar */
    /* .btn {
        margin-left: 57px;
        margin-right: 15px;
        margin-bottom: 20px;
    } */

    .container-head {
        max-width: 80%;
    }
    .button-margin{
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
    .button-yellow{
        border-radius: 29px;
        margin-bottom: 5%;
        
    }
    .list-item {
        padding-left: 0px;
    }
}


@media (max-width: 426px) {
    .list-item {
        padding: 15px 0;
    }
    .container-head {
        max-width: 100%;
    }

    /* .btn {
        margin: 15px auto;
    } */
    .nav-support{
        display: none !important;
    }
}