.footer-bg {
    background-color: #052b65;
    
}

.single-footer-widget {
    padding: 100px 0 90px;
    text-align: start;
}

.widget-title {
    /* margin-bottom: 30px; */
}

.layout-footer{
    width:20%
}

.foot-image-size{
    width: 7rem;
    height: 7rem;
}
.social-media > ul {
   padding-left: 0;
   display: flex;
   list-style: none;
   justify-content: space-between;
    width: 10rem;
    cursor: pointer;

}
.copyrights{

    font-size: 0.7rem;
    color: #fff;
}

.copyrights >div{

    margin-bottom: 1rem;


}

.copyrights > ul{
    padding-left: 0;
    display: flex;
    list-style: none;
    color: #fff;
    cursor: pointer;
    /* text-decoration: underline; */
    
}

.private{

    display: flex;
    flex-flow: column;
    /* -webkit-box-pack: justify; */
    justify-content: space-between;
}

.copyrights > ul >li{

    margin-right: 0.8rem;
}

.copyrights > ul >li > a{

    color: #fff;
    text-decoration-color: rgb(255, 255, 255);
    
}


.copyrights > ul >li:not(:last-child)::after {
    content: "|";
    margin-left: 1rem;
    /* text-decoration: none; */
}

.widget-title h2 {
    line-height: 1;
    font-size: 1rem;
    color: #fff!important;
    /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
    font-weight: 600;
    padding-bottom: 1rem;
    border-bottom: 1px solid #fff;
}
.footer-link {
    font-weight: 400;
    display: block;
    line-height: 34px;
    color: #ddd!important;
    text-decoration: none;

}

.footer-link:hover {
    color: #fc5185 !important; 
}

.footer-copy-right {
    padding: 20px 0;
    background-color: #000;
}

.creator{
    color: #fc5185;
    text-decoration: none;
}

.creator-sub{
    font-weight: 500;
    line-height: 25px;
}

.widget-content{
    /* word-break: break-all; */
    word-wrap: break-word;
    font-size: 0.8rem;
}

/* for mobile responsive */
@media only screen and (max-width: 426px){
    .single-footer-widget {
        padding: 45px 0 20px;
    }
    .creator-sub{
        font-size: 9px;
    }
}

@media only screen and (max-width: 321px){
    .footer-copy-right {
        padding: 20px 8px;
    }
    .creator-sub{
        font-size: 8px;
    }
}