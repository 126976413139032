.slider-app {
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    /* background-color: #000; */
    padding: 3rem;
  }

.slick-slide > div {
    margin: 0 10px;
  }
  .slick-list {
    margin: 0 -10px;
  }
  
  .carousel-card {
    margin: 6% 0%;
    /* border: 1px solid #000000; */
    background: rgb(255, 255, 255);
    /* background: #FEE1BD; */
    border-radius: 8px;
    overflow: hidden;
    height: 266px;
    max-height: fit-content;
    border: 1px solid rgb(228, 228, 228);
    color: #000;
    cursor: pointer;
    transition: background-color 300ms ease-in-out 0s;
    box-shadow: -20px 20px 0 -17px #fff,
    20px -20px 0 -17px #fff,
    20px 20px 0 -20px #000,
    0 0 0 2px #000;
  }
  
  .carousel-body h4 {
    font-size: 1.3rem;
    color: #088683;
  }

  .carousel-body{
    /* display: flex;
    flex-direction: column;
    margin-top: auto; */
    padding: 1rem;

  }

  .slick-arrow{
     /* background-color: #000;
     color: ; */
     background: none;
     border: none;
     cursor: pointer;
     -webkit-tap-highlight-color: transparent;
     text-shadow: 1px 1px 0px #7CACDE;
      box-shadow: 1px 1px 1px #BEE2F9;
      border-radius: 50%;
      border: 2px solid #FFFFFF;
      background: #FFFFFF;
      box-shadow: 3px 3px 5px 0px #80898f;
      margin-left: -8px;
      height: 40px;
      width: 40px;
  }

  .slick-prev:before, .slick-next:before {
    color: black;
  }

  .slick-next{
    /* left: 321px; */
  }

  .slick-arrow:hover {
    color: #14396A !important;
    background: #E8ECEF;
  }

  .carousel-header{
    margin: 3%;
 
  }

  .carousel-header > h3 {
    /* font-family: sofia-pro, serif; */
    font-weight: 400;
    color: rgb(255, 255, 255);
    font-size: 4.5rem;
    opacity: 1;
    /* transition: opacity 300ms ease-in-out 0s; */
    -webkit-text-stroke: 3px #69B3BF;
    }
  
  .carousel-header > img {
    position: absolute;
    object-fit: cover;
    top: 0.5rem;
    left: -0.25rem;
    max-width: 6.25rem;
    transition: opacity 300ms ease-in-out 0s;
    opacity: 0;
  }

  .carousel-header > img {
    margin: 10px 20px;
    filter: hue-rotate(90deg) !important;
    -webkit-filter: hue-rotate(90deg);
  }
  
  .carousel-body > p {
    font-size: 0.7rem;
    color: #34698b;
    font-family: 'Poppins', sans-serif;
  }
  
  .category {
    position: relative;
  }

  .category::before {
    content: '';
    background: rgb(255, 61, 61);
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
    height: 2px;
  }
  /* .button-arrow {
    background: none;
    border: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    
  } */

  .disable-focus-on-select {
    pointer-events: none !important;
    cursor: default;
    outline: none;

  }
  
  /* .slick-current .carousel-card {
    background: #E8ECEF!important; 
    border-radius: 0px 40px 0px 0px; 
    border: 4px solid #ccdbe6;
    box-shadow: 3px 3px 5px 0px #a3cdec;
  } */

  /* .slick-current .carousel-card .carousel-header img{
    opacity: 1;
  }
  .slick-current .carousel-card .carousel-header h3{
    opacity: 0;
  } */

  /* .carousel-button {
    text-shadow: 1px 1px 0px #7CACDE;
    box-shadow: 1px 1px 1px #BEE2F9;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    background: #FFFFFF;
    box-shadow: 3px 3px 5px 0px #80898f;
    margin-left: 20px;
    height: 50px;
    width: 50px;
  } */
  .carousel-button:hover {
    color: #14396A !important;
    background: #E8ECEF;
  }
  /* .carousel-btn-container{
    text-align: start;
    margin: 2%;
  }
   */


  @media (max-width: 445px) {
     /* .carousel-btn-container{

        display: none;
     } */

}