.testimonial-wrapper {
  /* background: linear-gradient(90deg, #880fc4 0%, #1d39f2 100%); */
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* font-family: "raleway, sans-serif"; */
  color: #fff;
}

.testimonial-wrapper h1 {
  font-size: 60px;
  font-weight: 500;
  margin: 50px 0;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
}

.testimonial-wrapper h5 {
  font-size: 20px;
  text-align: center;
  font-weight: 300;
  margin-bottom: 80px;
  font-family: 'Poppins', sans-serif;
}

.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 1000px; */
  margin: 0 auto;
}

.card-testimonial {
  width: 380px;
  /* height: 250px; */
  height: auto;
  border-radius: 15px;
  margin: 7px;
  padding: 20px 30px;
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 10px 20px 25px rgba(0, 0, 0, 0.2);
  margin-bottom: 11%;
  font-family: 'Poppins', sans-serif;
}

.card-testimonial img {
  height: 90px;
  width: 90px;
  border: 2px solid #f2f2f2;
  border-radius: 50%;
  position: absolute;
  top: -40px;
  left: 20px;
}

.card-testimonial p {
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 25px;
  min-height: 200px;
}

.card-testimonial h2 {
  font-weight: 500;
  margin-bottom: 4px;
}

.card-testimonial h4 {
  color: #333;
  font-weight: 400;
  font-size: 15px;
}

.card-testimonial i {
  font-size: 60px;
  position: absolute;
  bottom: 20px;
  right: 25px;
  color: #b2bec3;

}

.view-insta {
  text-decoration: none;
  /* background: none; */
  padding: 2px 5px;
  /* background: white; */
  background: rgba(255 255 255 / 25%);
  backdrop-filter: blur(63px);
  border-radius: 5px;
}

.card-testimonial:hover {
  transform: scale(0.9);
  color: #333;
  background: #fff;
  z-index: 1;
}

.review-slider {

  /* min-height: 300px; */
  padding-top: 45px;
  margin-bottom: 5%;

}

.rfm-marquee {
  z-index: 0 !important;
}

@media (max-width: 768px) {
  .card-testimonial {
    /* width: 100%;
    flex-direction: column;
    flex-wrap: wrap; */
  }

  .card-testimonial {
    /* margin: 40px 0; */
  }
}

@media (max-width: 445px) {

  .card-testimonial img {

    height: 80px;
    width: 80px;
  }

  .card-testimonial {
    width: 280px !important;

  }


}