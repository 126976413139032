.expert-human {
    padding: 80px 0;
    /* background-image: url('../../images/vectorpreview.png');
    background-position: bottom;
    background-repeat: no-repeat; */
    /* background-size: cover; */
    /* background-size: 242% 219%; */
}


.typescontest-txt h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;   
    margin-bottom: 23px;
    color: #222;
    /* font-family: DM Sans,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji; */
    font-family: 'Poppins', sans-serif;
}

.parttion-two{
 
    padding: 3.8rem;
    border-radius: 4.8rem;
    background-color: #F3DBDC;
    margin-left: 5%;
}
.typescontest-txt span {
    font-weight: 450;
    font-size: 15px;
    /* color: #000000; */
    font-family: 'Poppins', sans-serif;
}
.typescontest-txt li:not(last-child){
    padding-bottom: 16px;
    padding-top: 16px;
}

.typescontest-txt ul > li {
    /* list-style-image: url('../../images/x-rays.png'); */
    position: relative;
    list-style-type: none;
  }
 
.typescontest-txt ul > li:before {

    content: "";
    position: absolute;
    top: 16px;
    left: -42px;
    width: 30px;
    height: 30px;
    background-image: url('../../images/x-rays.png');
    background-repeat: no-repeat;

}  

.typescontest-txt{

    margin-top: 3rem;
}

.bottom{
    border-bottom: 0.5px solid #adadad;
}

.align-margin{
    /* margin-left:5% ; */
    margin-bottom: auto;
}
.sticky-img{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.bg-blue{
    border: 1px solid #46e0d3;
    background: #46e0d3;
   color: #000;
}

.bg-black{
    border: 1px solid #fff;
    background-color: #000000 !important;
   color: #fff;
}

.bg-black:hover {
    border-color: #000000;
    color: #000;
    background-color: #fff !important;
}

.bg-black:hover {
    background: #ffe770;
}


@media (max-width: 992px) {


    .align-margin{
        margin-left:0%;
        position: relative;
    }
    
    .expert-human{
        background-image:none !important;
        margin: 2%; 
       
    }
    .res-margin{
        margin-top:4%;
    }
    .parttion-two{
        /* margin-top:4%; */
        margin-left: 0%;
        /* margin: 2%; */
    }

}

@media (max-width: 480px) {

    .parttion-two{
        /* margin: 4% 2% 2% 0% !important; */
        margin: 10px 10px 10px 0 !important;
        padding: 1.8rem;
        border-radius: 3.4rem;
    }
    .typescontest-txt{

        margin-top: 1.5rem;
    }
    .rad-btn{

    }
}