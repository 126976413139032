.achivement-wrap {
    padding:  45px 0;
    /* margin-bottom: 120px; */
    /* background: #D5F3F3; */
    color: #000;
}

.conf-header{
    height: 4rem;
    background-color: #4DB05B;   
    display: flex;
    justify-content: center;
     align-items: center;
}
.conf-bottom{
    height: 8rem;    
    border: 2px solid #4DB05B;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}



