.appoinment-wrapper {
 
padding-top: 50px;
}


.appoitment-img{

    border-radius: 10px;
    background-image: url('../../images/Waiting.svg');
    padding: 48% 15% 32%;
    background-position: cover;
    background-repeat: space;
    background-size: contain;

}   

.price-cont{
    display: flex;
    justify-content: flex-end;
    
}

.pricing-tag{
    width: fit-content;
    border-radius: 1rem;
    text-align: center;
    padding: 1rem 2rem;
    color: #000;
      box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;

}

.pricing-tag >  h3{

  /* font-weight: 600; */
  font-size: 1.2rem;
  margin-bottom: 0;
  /* margin-bottom: 0.5rem; */
}

.pricing-tag:hover {
    transform: scale(1.04);
    box-shadow: 0px 5px 7px -6px rgba(0, 0, 0, 0.08),
      0px 8px 13.5px -6px rgba(0, 0, 0, 0.12),
      0px 16px 36px -6px rgba(0, 0, 0, 0.15);
  }

.price{

    /* font-weight: 600; */
    /* font-size: 1.2rem; */
    margin-bottom: 0;
    /* font-family: fangsong; */
    /* margin-top: auto; */
    /* line-height: 0.85; */
    text-align: center;
    
}

.packageprice{

    font-family: system-ui;
}



#phoneNo::-webkit-outer-spin-button,
#phoneNo::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
#phoneNo[type="number"] {
  -moz-appearance: textfield;
}

.booking-title > h1{
    position: relative;
    padding: 0;
    margin: 0;
    /* font-family: "Raleway", sans-serif; */
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 5px;

}

.booking-title > h1:before{
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -14px;
    background-color: #90CAF9;

}

.booking-title > h1:after {
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 25px;
    left: 50%;
    margin-left: -50px;
    background-color: #90CAF9;
  }

  .booking{
    /* padding: 50px; */
    box-shadow: 0 6px 26px rgb(0 0 0 / 6%);
    padding: 4rem;
    margin-bottom: 4rem;

  }
  .form-control::placeholder { 

    opacity: 0.2;
}
.body-address > p{

    font-size: 0.8rem;
}


                /* ###############pricing############### */


.pricing-wrapper{
    padding: 2rem;
    /* background-color: #90CAF9; */

}



.pricing-row2{

    /* border: 1px solid #000; */
}

.segement-price{
    padding: 0;
    border: 1px solid #000;
}

.top-segement > h4, .top-segement > p{
    font-weight: 100;
}
.top-segement{

    min-height: 19rem;
}

.top-segement > p{

    word-spacing: 0.2rem;
}

.second-segement{
    min-height: 28rem;
}

.boder-segment{
    padding: 2rem;
    border-bottom:1px dashed #000 ;
}

.second-segement > h6 ,.third-segement > h6{
    color: #828282;
}

.included-list{
list-style: none;
padding: 0!important;

}
.included-list > li{
        margin-bottom: 1rem;
}

.third-segement{
    padding: 2rem;
}


.ribbon {
    position: absolute;
    right: -5px; top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px; height: 75px;
    text-align: right;
  }
  .ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#9BC90D 0%, #79A70A 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px; right: -21px;
  }
  .ribbon span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #79A70A;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
  }
  .ribbon span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #79A70A;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
  }

/* .second-segement > .included-list > li:nth-child(2){

    font-weight: 600;

} */


@media (max-width: 450px) {

    .second-segement{
        min-height: fit-content;
    }
    .second-segement{
        min-height: fit-content;
    }
    .seg-ultra{
        margin-top: 2rem;

    }
    .webView-pricing, .pricing-row1{
        display: none;
    }
    .mobile-pricing{
        border: none;
        /* border-top: 5px solid #2CE2AD; */
        box-shadow: 1px 1px 4px rgba(0,0,0,.25);
        border-radius: 10px;
        padding: 1rem;
        margin-bottom: 2rem;
        text-decoration: none;
    
    }

    .pricing-bud{
        border-top: 5px solid #2CE2AD;
    }

    .pricing-pro{
        position: relative;
        border-top: 5px solid #9BC90D;
    }
   
    .included-mobile > h6{
        color: #828282;
        font-size: 0.8rem;
    }
    .included-mobile > ul{
        list-style: none;
        padding: 0!important;
        
        }
        .included-mobile > ul > li{
                margin-bottom: 0.5rem;
                font-size: 0.6rem;
        }
    .booking{
            padding: 2rem;
    }

    .price-cont{

        justify-content: center;
    }

}

@media (min-width: 450px) {
    
    .webView-pricing{
        display: flex;
    }

    .mobile-view{
        display:none;
    }
    .pricing-row1{
        padding: 2rem;
        text-align: center;
        border: 1px solid #000;
        border-bottom: none;
        
    }

}

