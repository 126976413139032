@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


::-webkit-scrollbar {
  width: 0px;
}

body {
  margin: 0;

  font-family: 'Poppins', sans-serif;
}


.basic-btn {
  display: inline-block;
  height: 61px;
  text-align: center;
  line-height: 55px!important;
  padding: 0 55px;
  text-transform: capitalize;
  position: relative;
  border-radius: 3px;
  transition: .4s;
  font-weight: 600;
}

.rad-btn{
  position: relative;
  display: inline-block;
  line-height: 1.5rem;
  padding: 0px 3.2rem;
  text-transform: capitalize;
  border-radius: 4rem;
  font-size: 1rem;
  font-weight: 600;
  max-width: 100%;
  text-align: center;
  /* padding: 1.4rem 2.4rem 1.2rem; */
  padding: 0.6rem 1rem 0.6rem;
  margin: 0px 4px 4px 0px;
}

.button {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 2rem;
  padding: 1rem 2rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  transition: .4s;
}

.text-decort{
  text-decoration: none !important;
}


