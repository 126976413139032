
.question-wrapper {
    padding-bottom: 120px;
}

.text-primary{
    color : #5a8dee !important;
}

.card-question {
     /* box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3); */
    background-color: #fff;
     /* border-radius: 8px; */
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #475f7b;
    overflow: hidden;
    border: none;
}

.rc-accordion-toggle {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    justify-content: space-between;
    /* background-color: #ebedf0; */
    transition: 0.3s;
    
}
.rc-accordion-toggle.active{
    background-color: #A9F1DF;
    color: #000 !important;
    border-radius: 24px;
}
.rc-accordion-toggle.active .rc-accordion-icon{
    transform: rotate(180deg);
    color: #000;
}
.rc-accordion-card{
    /* border: 1px solid #ddd; */
    /* border-radius: 5px; */
    margin-bottom: 10px;
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
}
.rc-accordion-card:last-child{
    margin-bottom: 0;
}
.rc-accordion-title{
    font-weight: 500;
    font-size: 14px;
    position: relative;
    margin-bottom: 0;
    color: #475F7B;
    transition: 0.3s;
}
.rc-accordion-toggle.active .rc-accordion-title{
    color: #000;
}
.rc-accordion-icon{
    position: relative;
    top: 2px;
    color: #475F7B;
    transition: 0.35s;
    font-size: 12px;
}
.rc-accordion-body{
    flex: 1 1 auto;
    min-height: 1px;
    padding: 15px;
}
.rc-accordion-body p{
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #727E8C;
}
.rc-collapse{
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}
.rc-collapse.show{
    height: auto;
}


.six{
    margin-top: 3%;
}
.six h1 {
    text-align: center;
    font-size:30px; font-weight:400;
    text-transform: uppercase;
    word-spacing: 1px; letter-spacing:2px; 
  }
  .six h1 span {
    line-height:2em; padding-bottom:15px;
    text-transform: none;
    font-size:.7em;
    font-weight: normal;
    /* font-style: italic; font-family: "Playfair Display","Bookman",serif; */
    font-family: 'Poppins', sans-serif;
    color:#999; letter-spacing:-0.005em; word-spacing:1px;
    letter-spacing:none;
  }
  .six h1:after, .six h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 45px;
    height: 4px;
    content: "";
    right: 45px; 
    margin:auto;
    background-color: #ccc;
  }
  .six h1:before { 
  background-color:#A9F1DF;
    left:45px; width:90px;
  }

  .six h1 {
    position: relative;
    padding: 0;
    margin: 0;
    /* font-family: "Raleway", sans-serif; */
    font-weight: 300;
    font-size: 40px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  .six h1 span {
    display: block;
    font-size: 0.5em;
    line-height: 1.3;
  }
  .six h1 em {
    font-style: normal;
    font-weight: 600;
  }

  .question-img img{
    position: sticky;
    top: 0;
    max-width: 100%;
    height: auto;
  }

  .question-img div{
    
    width: 100%;
    height: 100%;

    }


  @media (max-width: 992px) {

    .question-img img{
    max-width: 50%;
    }
  }

  .ant-collapse-item{
      /* border-bottom: 5px solid #475F7B; */
  }
  .custom-accordion{
    border: 0;
  }

  .custom-accordion > .ant-collapse-item{

    margin-bottom: 1rem;
    border: 0;


  }

  .custom-accordion > .ant-collapse-item-active{

      background-color: #a9f1df;
      /* border-radius: 24px; */
      color: #000;

  }