.promo-wrapper {

    padding: 2%;
}

.promo-content h1 {
    color: black;
    font-size: 3.8rem;
    font-weight: 400;
    line-height: 1.1;
    white-space-collapse: initial;
    text-wrap: wrap;
    /* font-family: sofia-pro, serif; */
    text-align: inherit;
    margin: 2%;

}

.promo-banner {
    margin-top: 32px;
    position: relative;
    z-index: 1;
}

.promo-banner img {
    max-width: 70%;
    height: auto;
}

.promo_container{
/* margin: 3%; */
border-radius: 4.8rem;
  background-color: #F3F5F3;
  
}

.promo-content{
  margin: 5%;
  background-color: #FFF;
  color: black;
  border-radius: 2.8rem;
  padding: 2.5rem 2.2rem 2.4rem;

}

.promo-content > p{

  color: black;
  font-family: 'Poppins', sans-serif;
}
.fun-fact-sec {

  margin: 5%;
  border-radius: 2rem;
  position: relative;
  height: 100%;
  padding: 4.5rem 0rem 4.5rem 3rem;
  overflow: hidden;
}

.single-fun {
  position: relative;
  width: 50%;
  margin-bottom: 45px;
  float: left; 
  text-align: start;
}


.sp-fun {
  padding-left: 3rem;
}

.single-fun span {
  font-weight: 600;
  font-size: 3rem;
  line-height: 1;
  position: relative;
  color: #FEC538;
}

.single-fun p {
  color: #000;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.01em;
  margin-top: 8px;
}

.line {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 1px;
  height: 70%;
  background: #dadada;
  content: "";
}

.sp-pad{

padding-right: 3rem;
}



/* for mobile responsive */

@media only screen and (max-width: 426px) {
  .mt-sm-req {
    margin-top: 3rem;
}

  .promo-content h1 {
    font-size: 3rem;
  }
  .promo-content{

    padding: 1.3rem 1.3rem 2.4rem;

  }
  .fun-fact-sec{
    padding: 2rem 0rem 2rem 0rem !important;
    border-radius: 3.8rem !important;
  }

  .promo_container{
  
     border-radius: 3.8rem !important;
      
    }

}

@media (max-width: 680px) {

  .fun-fact-sec{
    padding: 4.5rem 0rem 4.5rem 0rem;
    border-radius: 3.8rem !important;
  }
    .sp-pad{
    padding-right: 1rem;
    }
    
    .sp-fun {
      padding-left: 2rem;
    }

}

@keyframes pulse {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(.9);
    }
      100% {
      transform: scale(1);
    }
  }


  .button-segment{
    display: block;
  }


  .custom-btn {
    /* font-family: 'courier new'; */
    font-weight: bold;
    color: #000000 !important;
    font-size: 14px;
    text-shadow: 1px 1px 0px #DEDEDE;
    box-shadow: 0px 0px 1px #BEE2F9;
    padding: 31px 40px;
    border-radius: 10px;
    /* border: 0.2px solid #B0B0B0; */
    background: #FFFFFF;
    width: 100%;
  }
  .custom-btn:hover {
    color: #14396A !important;
    background: #EBFAF6;
  }